<template>
<div class="player__progression">
    <div class="grid grid-cols-2 player__progression__grid">
        <div class="player__progression__bloc">
            <div class="player__progression__bloc__line text-left">
                <span class="text-white label">Progression</span>
            </div>
            <div class="player__progression__bloc__line text-left">
                <strong class="text-white value" v-if="UserStore.percentDone != null">{{ percentProgress }}%</strong>
                <vue-content-loading :width="80" :height="30" v-else>
                    <rect x="0" y="0" rx="4" ry="4" width="35" height="25" />
                </vue-content-loading>
            </div>
        </div>
        <div>
            <div class="player__progression__bloc__line text-right">
                <span class="text-white label text-right">Temps</span>
            </div>
            <div class="player__progression__bloc__line text-right">
                <strong class="text-white value text-right" v-if="UserStore.timeSpent">{{ timeSpent }}</strong>
                <vue-content-loading :width="80" :height="30" v-else>
                    <rect x="40" y="0" rx="4" ry="4" width="40" height="25" />
                </vue-content-loading>
            </div>
        </div>
    </div>
    <div class="player__progression__bar">
        <div class="player__progression__bar__white" :style="{width: percentProgress + '%'}"></div>
    </div>
</div>
</template>

<script>

import { useUserStore } from "@/store/UserStore";
import { VueContentLoading } from 'vue-content-loading';

export default {
    data () {     
        const UserStore = useUserStore();   
        return {
            UserStore: UserStore,
            avatar: null,
        }
    },
    components: {
        VueContentLoading,
    },
    computed: {
        percentProgress() {
            return this.UserStore.percentDone
        },
        timeSpent() {
            return this.UserStore.timeSpent
        }
    }
}
</script>