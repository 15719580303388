<template>
    <ul class="home__parcours__list__pagination flex items-center justify-center">
        <li class="home__parcours__list__pagination__item" v-for="page in pages" :key="page"
        @click="changePage(page)" :class="{active: page === current}">
            <span v-if="page > 1">-</span>
            <a>{{ page }}</a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        pages: Number,
        current: Number
    },
    methods: {
        changePage(page) {
            this.$emit('page-updated', page)
        }
    }
}
</script>
