<template>
    <div class="player__info text-center md:text-right" v-if="UserStore.userFname">
        <strong class="player__info__name text-white">{{ name }}</strong>
        <br>
        <span class="player__info__last-connection text-white" v-if="UserStore.userLastConnectionDate">Dernière connexion le {{ UserStore.userLastConnectionDate }} à {{ UserStore.userLastConnectionHour }}</span>
    </div>
    <div class="player__info text-right" v-else>
        <vue-content-loading :width="285" :height="40">
            <rect x="75" y="3" rx="4" ry="4" width="100" height="15" />
            <rect x="185" y="3" rx="4" ry="4" width="100" height="15" />
            <rect x="135" y="25" rx="4" ry="4" width="150" height="10" />
        </vue-content-loading>
    </div>
</template>

<script>

import { useUserStore } from "@/store/UserStore";
import { VueContentLoading } from 'vue-content-loading';

export default {
    data () {     
        const UserStore = useUserStore();   
        return {
            UserStore: UserStore,
            avatar: null,
        }
    },
    components: {
        VueContentLoading,
    },
    computed: {
        name() {
            return this.UserStore.userFname + ' ' + this.UserStore.userLname
        },
        lastConnectionDate () {
            return this.$moment(this.lastConnection).format('DD/MM/YYYY')
        },
        lastConnectionTime () {
            let h = this.$moment(this.lastConnection).format('HH')
            let m = this.$moment(this.lastConnection).format('MM')

            return h + 'h' + m
        }
    }
}
</script>