var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth__signin__form"},[_c('h2',[_vm._v("Bienvenue !")]),_c('h3',[_vm._v("Connectez-vous à votre compte")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"field-holder"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],class:{
                'error': _vm.signinError
            },attrs:{"type":"text","required":"","id":"username","disabled":_vm.processing},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}}),_c('label',{staticClass:"input__label",attrs:{"for":"username"}},[_vm._v("Votre identifiant")])]),_c('div',{staticClass:"field-holder"},[((_vm.passwordType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
                'error': _vm.signinError
            },attrs:{"required":"","id":"password","disabled":_vm.processing,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.passwordType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
                'error': _vm.signinError
            },attrs:{"required":"","id":"password","disabled":_vm.processing,"type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:{
                'error': _vm.signinError
            },attrs:{"required":"","id":"password","disabled":_vm.processing,"type":_vm.passwordType},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('label',{staticClass:"input__label",attrs:{"for":"password"}},[_vm._v("Votre mot de passe")]),_c('TogglePasswordDisplay',{on:{"togglePassword":_vm.togglePassword}})],1),_c('div',{staticClass:"field-holder inline"},[_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.remember),expression:"remember"}],attrs:{"type":"checkbox","value":"1"},domProps:{"checked":Array.isArray(_vm.remember)?_vm._i(_vm.remember,"1")>-1:(_vm.remember)},on:{"change":function($event){var $$a=_vm.remember,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.remember=$$a.concat([$$v]))}else{$$i>-1&&(_vm.remember=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.remember=$$c}}}}),_c('span',{staticClass:"slider"}),_c('span',{staticClass:"label"},[_vm._v("Se souvenir de moi")])]),_c('router-link',{attrs:{"to":{ name: 'auth.lost-id' }}},[_vm._v("Mot de passe oublié ?")])],1),_c('button',{staticClass:"auth__signin__form__submit",style:({'background-color': _vm.ThemeStore.loginPage.buttonColor}),attrs:{"type":"submit","disabled":_vm.processing}},[_vm._v("Je me connecte")])])])}
var staticRenderFns = []

export { render, staticRenderFns }