<template>
    <div class="home__statistics text-center p-6" v-if="UserStore.statistics != null">
        <div class="text-center">
            <img src="@/assets/icons/icon-statistics.png" alt="" class="mx-auto">
            <h2>Mes statistiques</h2>
        </div>
        <div class="mt-6 mx-auto rounded-2xl home__statistics__bloc">
            <div class="home__statistics__tabs flex justify-center p-6 pb-0 rounded-t-2xl">
                <div class="grid grid-cols-2">
                    <div class="home__statistics__tab py-6 px-4 rounded-t-2xl" :class="{selected: tab == 0}" @click="switchTab">
                        <span>Compétences de base</span>
                    </div>
                    <div class="home__statistics__tab py-6 px-4 rounded-t-2xl" :class="{selected: tab == 1}" @click="switchTab">
                        <span>Compétences techniques</span>
                    </div>
                </div>
            </div>
            <div class="home__statistics__content p-6">
                <img :src="radar" alt="" class="radar mt-4">
                <div class="separator"></div>
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="grid grid-flow-col items-center auto-cols-auto p-4" v-for="(chart,idx) in charts" :key="idx" v-if="chart.graph">
                        <div class="visual">
                            <img :src="chart.graph" alt="" class="chart">
                        </div>
                        <div class="label ml-2 text-left">
                            <strong>{{chart.name}}</strong>
                            <p v-if="chart.description">{{chart.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import { useUserStore } from "@/store/UserStore";

export default {
    data () {     
        const UserStore = useUserStore();   
        return {
            UserStore: UserStore,
            tab: 0
        }
    },
    computed: {
        radar() {
            const stats = this.UserStore.statistics
            
            if (this.tab == 0 && stats.basic) { // basic
                return stats.basic.graph
            }

            if (stats.tech) {
                return stats.tech.graph
            }
        },
        charts() {
            const stats = this.UserStore.statistics

            if (this.tab == 0 && stats.basic) { // basic
                return stats.basic
            }

            if (stats.tech) {
                return stats.tech.graph
            }
        },
    },
    methods: {
        switchTab() {
            this.tab = this.tab == 0 ? 1 : 0;
        }
    }
}
</script>