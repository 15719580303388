import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
      avatar: null,
      fname: null,
      lname: null,
      login: null,
      groupName: null,
      groupCity: null,
      lastConnectionDate: null,
      lastConnectionHour: null,
      documentsToDownload: 0,
      percentDone: null,
      score: 0,
      maxScore: 0,
      timeSpent: 0,
      lockedTrophiesCount: 0,
      documentsCount: 0,
      statistics: null
  }),
  getters: {
    userAvatar() {
      return this.avatar
    },
    userFname() {
      return this.fname
    },
    userLname() {
      return this.lname
    },
    userLogin() {
      return this.login
    },
    userGroupName() {
      return this.groupName
    },
    userGroupCity() {
      return this.groupCity
    },
    userLastConnectionDate() {
      return this.lastConnectionDate
    },
    userLastConnectionHour() {
      return this.lastConnectionHour
    },
    userDocumentsToDownload() {
      return this.documentsToDownload
    },
    userPercentDone() {
      return this.percentDone
    },
    userScore() {
      return this.score
    },
    userMaxScore() {
      return this.maxScore
    },
    userTimeSpent() {
      return this.timeSpent
    },
    userLockedTrophiesCount() {
      return this.lockedTrophiesCount
    },
    userDocumentsCount() {
      return this.documentsCount
    },
    userStatistics() {
      return this.statistics
    }
  },
  actions: {
    reset() {
      this.avatar = null
      this.fname = null
      this.lname = null
      this.login = null
      this.groupName = null
      this.groupCity = null
      this.lastConnectionDate = null
      this.lastConnectionHour = null
      this.documentsToDownload = 0
      this.percentDone = null
      this.score = 0
      this.maxScore = 0
      this.timeSpent = 0
      this.lockedTrophiesCount = 0
      this.documentsCount = 0
      this.statistics = null
    },
    setAvatar(val) {
      this.avatar = val
    },
    setFname(val) {
      this.fname = val
    },
    setLname(val) {
      this.lname = val
    },
    setLogin(val) {
      this.login = val
    },
    setGroupName(val) {
      this.groupName = val
    },
    setGroupCity(val) {
      this.groupCity = val
    },
    setLastConnectionDate(val) {
      this.lastConnectionDate = val
    },
    setLastConnectionHour(val) {
      this.lastConnectionHour = val
    },
    setDocumentsToDownload(val) {
      this.documentsToDownload = val
    },
    setPercentDone(val) {
      this.percentDone = val
    },
    setScore(val) {
      this.score = val
    },
    setMaxScore(val) {
      this.maxScore = val
    },
    setTimeSpent(val) {
      this.timeSpent = val
    },
    setLockedTrophiesCount(val) {
      this.lockedTrophiesCount = val
    },
    setDocumentsCount(val) {
      this.documentsCount = val
    },
    setStatistics(val) {
      this.statistics = val
    }
  },
})
