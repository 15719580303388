<template>
    <div class="auth__signin__form lostpassword__form">
        <div class="auth__signin__form__description">
            <h2>Vous avez oublié votre identifiant ou votre mot de passe ?</h2>
            <p>Nous vous invitons à remplir le formulaire pour envoyer un email au support technique en indiquant votre <strong>nom</strong>, <strong>prénom</strong> et le <strong>nom de votre structure</strong>.</p>
        </div>

        <form @submit.prevent="submit">
            <div class="field-holder">
                <input type="text" required v-model="lastName" id="lname"
                :class="{
                    'error': resetError
                }" :disabled="processing"/>
                <label for="lname" class="input__label">Nom</label>
            </div>
            <div class="field-holder">
                <input type="text" required v-model="firstName" id="fname"
                :class="{
                    'error': resetError
                }" :disabled="processing"/>
                <label for="fname" class="input__label">Prénom</label>
            </div>
            <div class="field-holder">
                <input type="text" required v-model="structure" id="structure"
                :class="{
                    'error': resetError
                }" :disabled="processing"/>
                <label for="structure" class="input__label">Votre structure</label>
            </div>
            <g-recaptcha class="auth__lostpassword__form__submit"
                :data-sitekey="recaptchaSiteKey" 
                :data-validate="validate"
                :data-callback="submit"
                :data-btn-disabled="processing">
                Envoyer la demande
            </g-recaptcha>
            <router-link :to="{ name: 'auth.signin' }"
            class="auth__lostpassword__form__submit btn-back">Retour à l'accueil</router-link>
        </form>
    </div>
</template>
<script>
import gRecaptcha from '@finpo/vue2-recaptcha-invisible';
import { useThemeStore } from "@/store/ThemeStore";
export default {
    props: {
        resetError: Boolean,
        processing: Boolean
    },
    components: {
        gRecaptcha,
    },
    data() {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            lastName: null,
            firstName: null,
            structure: null,
        }
    },
    computed: {
        recaptchaSiteKey() {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY
        }
    },
    methods: {
        validate () {
            if (!this.lastName || !this.firstName || !this.structure) {
                return false
            }
            
            return true
        },
        submit (captchaToken) {
            this.$emit('processed', {
                lastName: this.lastName,
                firstName: this.firstName,
                structure: this.structure,
                captchaToken: captchaToken,
            })
        },
    }
}
</script>
