<template>
    <div class="flex flex-row flex-wrap">
        <tips class="sm:w-1/3 md:w-1/4"/>
        <CurrentParcours :item="item" v-if="item" class="sm:w-2/3 md:w-3/4"/>
    </div>
</template>

<script>
import CurrentParcours from './Cards/Current.vue';
import Tips from './Tips/Index.vue';

export default {
    props: {
        item: Object
    },
    components: {
        CurrentParcours,
        Tips
    },
    data () {
        return {
        }
    }
}
</script>
