<template>
    <auth-layout @processed="processSignin"
    :processing="processing"
    :signinError="signinError"/>
</template>

<script>
import UserMixin from '@/mixins/userMixin'
import AuthLayout from '@/components/Auth/Signin/Layout.vue'

export default {
    mixins: [UserMixin],

    data () {
        return {
            signinError: false,
            processing: false
        }
    },

    components: {
        AuthLayout,
    },
    methods: {
        processSignin (val) {
            if (this.processing) {
                return false
            }
            this.processing = true
            const url = process.env.VUE_APP_BASE_URL + '/connect/token'
            const username = process.env.VUE_APP_CLIENT
            const password = process.env.VUE_APP_CLIENT_SECRET
            const remember = val.remember == 1
            
            const token = btoa(username + ':' + password)

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Basic ' + token
            }

            this.$http.post(url, new URLSearchParams({
                grant_type: 'password',
                username: val.username,
                password: val.password,
                scope: 'openid profile player data offline_access'
            }), { headers }).then(
                res => {
                    // save in cookie access_token
                    this.$cookies.config(res.data.expires_in,'/');
                    this.$cookies.set('access_token', res.data.access_token);

                    if (remember) { // save in cookie refresh_token
                        this.$cookies.set('refresh_token', res.data.refresh_token);
                    } else {
                        this.$cookies.remove('refresh_token')
                    }

                    this.fetchUser(() => {
                        this.$router.push({name: 'home.index'})
                        this.processing = false
                    })
                },
                error => {
                    this.signinError = true
                    this.processing = false
                }   
            )
        },
    }
}
</script>