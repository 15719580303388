<template>
    <auth-layout @processed="processReset"
    :processing="processing"
    :resetProcessed="resetProcessed"
    :resetError="resetError"/>
</template>

<script>
import UserMixin from '@/mixins/userMixin'
import AuthLayout from '@/components/Auth/LostPassword/Layout.vue'

export default {
    mixins: [UserMixin],

    data () {
        return {
            resetProcessed: false,
            resetError: false,
            processing: false
        }
    },

    components: {
        AuthLayout
    },
    methods: {
        processReset (res) {
            if (this.processing) {
                return false
            }
            this.processing = true

            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/forgotPassword';

            this.$http.get(url, {
                params: res
            }).then(
                res => {
                    this.resetProcessed = true
                    this.processing = false
                },
                error => {
                    this.resetError = true
                    this.processing = false
                }
            )
        }
    }
}
</script>