<template>
    <div class="thematics__filter">
        <ul>
            <li v-for="(filter, index) in filterItems" :key="index">
                <label>
                    <input type="checkbox" v-model="filters" :value="filter.key"
                    @click="checkAtLeastOne">
                    <span>{{ filter.label }}</span>
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data () {
        return {
            filters: [
                'pdr',
                'hes',
                'peh',
                'eeb',
                'fdv'
            ]
        }
    },
    watch: {
        filters: {
            handler() {
                const filters = this.filters.join()
                this.$emit('filters-updated', filters)
            },
            deep: true
        }
    },
    computed: {
        filterItems() {
            return [
                {
                    label: 'Prévention des risques',
                    key: 'pdr'
                },
                {
                    label: 'Hygiène et sécurité',
                    key: 'hes'
                },
                {
                    label: 'Pathologie et handicap',
                    key: 'peh'
                },
                {
                    label: 'Ethique et bientraitance',
                    key: 'eeb'
                },
                {
                    label: 'Fin de vie',
                    key: 'fdv'
                }
            ]
        }
    },
    methods: {
        checkAtLeastOne(event) {
            if (this.filters.length === 1 && this.filters.includes(event.target.value)) {
                event.preventDefault();
            }
        }
    }
}
</script>