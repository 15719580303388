<template>
    <div class="toggle__password__display">
        <img src="@/assets/icons/icon-show.png" alt=""
        class="cursor-pointer auth__signin__form__togglepassword"
        @click="togglePassword" v-if="!display"/>
        <img src="@/assets/icons/icon-hide.png"
        alt=""
        class="cursor-pointer auth__signin__form__togglepassword"
        @click="togglePassword" v-if="display"/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            display: false
        }
    },
    methods: {
        togglePassword () {
            this.display = !this.display
            this.$emit('togglePassword')
        }
    }
}
</script>