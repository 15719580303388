<template>
    <header class="new__home__header flex relative">
        <div class="header__navigation flex justify-between absolute w-full p-4">
            <div class="home__header__menu">
                <a href="#" class="home__header__menu__active">Accueil</a>
                <div class="home__header__menu__divider"></div>
                <a href="#">Aide ?</a>
            </div>
            <dropdown-menu/>
        </div>
        <div class="flex-grow hidden md:block text-center">
            <img :src="ThemeStore.boxedLogo.url" :alt="ThemeStore.boxedLogo.name" class="home__header__logo mx-auto">
            <div class="home__header__titlebox" v-if="ThemeStore.pageTitle || ThemeStore.pageSubTitle">
                <h3 class="home__header__pagetitle" v-if="ThemeStore.pageTitle">{{ ThemeStore.pageTitle }}</h3>
                <p class="home__header__pagesubtitle" v-if="ThemeStore.pageSubTitle">{{ ThemeStore.pageSubTitle }}</p>
            </div>
        </div>
    </header>
</template>

<script>
import DropdownMenu from './header/DropdownMenu.vue'
import { useThemeStore } from "@/store/ThemeStore";

export default {
    data () {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore
        }
    },
    components: {
        DropdownMenu
    },
    methods: {
    }
}
</script>