<template>
<div class="sidebar">
    <div class="sidebar__cta cursor-pointer" :class="{
        hidden: sidebar_show,
        block: !sidebar_show
    }" @click="toggle">
        <div class="header__menu-label text-white pl-4 md:pl-0 w-16 md:w-36 flex items-center justify-start">
            <img src="@/assets/icons/icon-menu-off.svg" alt="">
            <span class="ml-2 hidden md:block">Menu</span>            
        </div>
    </div>
    <div class="sidebar__menu" :class="{
        hidden: !sidebar_show,
        block: sidebar_show
    }">
        <header class="sidebar__menu__header">
            <div class="flex justify-between">
                <div class="rounded-full sidebar__menu__avatar flex items-center justify-center mb-4">
                    <img :src="UserStore.userAvatar" alt="" class="w-full rounded-full">
                </div>
                <div>
                    <img src="@/assets/icons/icon-closemenu.svg" alt="" class="cursor-pointer" @click="toggle">
                </div>
            </div>
            <strong class="text-3xl font-semibold text-white sidebar__user-profile__name">{{ name }}</strong>
            <br>
            <span class="text-sm font-normal text-white sidebar__user-profile__last-connection" v-if="UserStore.userLastConnectionDate">Dernière connexion<br>le {{ UserStore.userLastConnectionDate }} à {{ UserStore.userLastConnectionHour }}</span>
        </header>
        <nav class="sidebar__menu__list" v-if="1 == 0">
            <ul>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.554" height="18.227" viewBox="0 0 16.554 18.227">
                            <g id="icon_home_off" data-name="icon home off" transform="translate(0.75 0.75)">
                                <path id="Path_122" data-name="Path 122" d="M3,7.854,10.527,2l7.527,5.854v9.2a1.673,1.673,0,0,1-1.673,1.673H4.673A1.673,1.673,0,0,1,3,17.054Z" transform="translate(-3 -2)" fill="none" stroke="#b8add3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <path id="Path_123" data-name="Path 123" d="M9,20.363V12h5.018v8.363" transform="translate(-3.98 -3.637)" fill="none" stroke="#b8add3" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </svg>
                    </span>
                    <a href="#">Accueil</a>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.554" height="18.227" viewBox="0 0 16.554 18.227">
                        <g id="icon_parcours_off" data-name="icon parcours off" transform="translate(0.75 0.75)">
                            <g id="icon_parcours_off-2" data-name="icon parcours off" opacity="0.6">
                            <rect id="Rectangle_449" data-name="Rectangle 449" width="15.054" height="15.054" rx="2" transform="translate(0 1.673)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <line id="Line_53" data-name="Line 53" y2="3.345" transform="translate(10.872 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <line id="Line_54" data-name="Line 54" y2="3.345" transform="translate(4.182 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <line id="Line_55" data-name="Line 55" x2="15.054" transform="translate(0 6.691)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </g>
                        </svg>
                    </span>
                    <span>Parcours de formation</span>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.899" height="16.608" viewBox="0 0 19.899 16.608">
                        <g id="icon_community_off" data-name="icon community off" transform="translate(0.75 0.804)">
                            <g id="icon_community_off-2" data-name="icon community off" opacity="0.6">
                            <path id="Path_242" data-name="Path 242" d="M14.381,20.018V18.345A3.345,3.345,0,0,0,11.036,15H4.345A3.345,3.345,0,0,0,1,18.345v1.673" transform="translate(-1 -4.964)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <circle id="Ellipse_74" data-name="Ellipse 74" cx="3.345" cy="3.345" r="3.345" transform="translate(3.345 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Path_243" data-name="Path 243" d="M22.509,20.039V18.367A3.345,3.345,0,0,0,20,15.13" transform="translate(-4.11 -4.985)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Path_244" data-name="Path 244" d="M16,3.13a3.345,3.345,0,0,1,0,6.482" transform="translate(-3.455 -3.021)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </g>
                        </svg>
                    </span>
                    <a href="#">Communauté</a>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg id="icon_notification_off" data-name="icon notification off" xmlns="http://www.w3.org/2000/svg" width="17.055" height="17.071" viewBox="0 0 17.055 17.071">
                        <path id="icon_notification_off-2" data-name="icon notification off" d="M12.971,17.631h0a1.94,1.94,0,1,1-3.88,0H3.272a.811.811,0,0,1-.621-1.319l1.94-2.25a2.528,2.528,0,0,0,.621-1.474V8.32a5.82,5.82,0,1,1,11.639,0v4.268a3.719,3.719,0,0,0,.543,1.552l1.94,2.25a.8.8,0,0,1-.621,1.319H12.971Zm3.259-2.483a4.3,4.3,0,0,1-.931-2.561V8.32a4.268,4.268,0,1,0-8.535,0v4.268a4.3,4.3,0,0,1-.931,2.561l-.854.931h12.1l-.854-.931Z" transform="translate(-2.482 -2.5)" fill="#b8add3"/>
                        </svg>
                    </span>
                    <a href="#">Notifications</a>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg id="icon_messenger_off" data-name="icon messenger off" xmlns="http://www.w3.org/2000/svg" width="16.295" height="16.334" viewBox="0 0 16.295 16.334">
                        <path id="icon_messenger_off-2" data-name="icon messenger off" d="M5.463,5.613V4.057A1.525,1.525,0,0,1,6.944,2.5H17.314a1.525,1.525,0,0,1,1.481,1.557v7.394a1.525,1.525,0,0,1-1.481,1.557h-.741v2.724a.737.737,0,0,1-1.259.545l-.889-.934H9.092L5.981,18.612a.737.737,0,0,1-1.259-.545V15.343H3.981A1.525,1.525,0,0,1,2.5,13.786V7.17A1.525,1.525,0,0,1,3.981,5.613Zm9.629,6.616a.718.718,0,0,1,.741-.778h1.481V4.057H6.944v7.394H12.5a.658.658,0,0,1,.518.234l2.074,2.179V12.229ZM5.463,7.17H3.981v6.616H5.463a.718.718,0,0,1,.741.778V16.2L8.277,14.02a.658.658,0,0,1,.518-.234h4.148l-.741-.778H6.944a1.525,1.525,0,0,1-1.481-1.557V7.17Z" transform="translate(-2.5 -2.5)" fill="#b8add3"/>
                        </svg>
                    </span>
                    <a href="#">Messagerie</a>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.398" height="17.398" viewBox="0 0 17.398 17.398">
                        <g id="icon_stat_off" data-name="icon stat off" transform="translate(0.5 0.5)">
                            <path id="icn_activity" d="M1,9.381H3.824L6.011,17.4,9.564,1l3.189,11.023L14.574,7.1H17.4" transform="translate(-1 -1)" fill="none" stroke="#b8add3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                        </svg>
                    </span>
                    <a href="#">Statistiques</a>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg id="icon_setup_off" data-name="icon setup off" xmlns="http://www.w3.org/2000/svg" width="14.545" height="14.545" viewBox="0 0 14.545 14.545">
                        <path id="icon_setup_off-2" data-name="icon setup off" d="M15.66,4.024h0a2.05,2.05,0,0,1,0,3.879v8.45a.693.693,0,0,1-1.385,0V7.9a2.05,2.05,0,0,1,0-3.879V3.193a.693.693,0,1,1,1.385,0v.831Zm-.693,2.632a.654.654,0,0,0,.693-.693.693.693,0,1,0-1.385,0,.654.654,0,0,0,.693.693ZM5.27,7.833h0a2.05,2.05,0,0,1,0,3.879v4.641a.654.654,0,0,1-.693.693.654.654,0,0,1-.693-.693V11.712a2.05,2.05,0,0,1,0-3.879V3.193A.654.654,0,0,1,4.578,2.5a.654.654,0,0,1,.693.693V7.833Zm-.693,2.632a.693.693,0,1,0,0-1.385.693.693,0,1,0,0,1.385Zm5.887-7.272v8.45a2.05,2.05,0,0,1,0,3.879v.831a.693.693,0,0,1-1.385,0v-.831a2.05,2.05,0,0,1,0-3.879V3.193a.693.693,0,1,1,1.385,0ZM9.772,14.274a.693.693,0,1,0-.693-.693.654.654,0,0,0,.693.693Z" transform="translate(-2.5 -2.5)" fill="#b8add3"/>
                        </svg>
                    </span>
                    <a href="#">Réglages</a>
                </li>
                <li class="flex items-center">
                    <span class="sidebar__menu__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.045" height="16.554" viewBox="0 0 14.045 16.554">
                        <g id="icon_profil_off" data-name="icon profil off" transform="translate(0.75 0.75)">
                            <g id="icon_profil_off-2" data-name="icon profil off" opacity="0.6">
                            <path id="Path_238" data-name="Path 238" d="M13.545,20.018V18.345A3.345,3.345,0,0,0,10.2,15H4.345A3.345,3.345,0,0,0,1,18.345v1.673" transform="translate(-1 -4.964)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <circle id="Ellipse_70" data-name="Ellipse 70" cx="3.345" cy="3.345" r="3.345" transform="translate(2.927 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </g>
                        </svg>
                    </span>
                    <a href="#">Mon profil</a>
                </li>
            </ul>
        </nav>
        <div class="sidebar__logout">
            <div class="flex items-center" @click="logout">
                <span class="sidebar__menu__icon">
                    <img src="@/assets/icons/icon-logout.png" alt="">
                </span>
                <a href="#">Déconnexion</a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { useUserStore } from "@/store/UserStore";
export default {
    props: {
        status: Boolean
    },
    data () {
        const UserStore = useUserStore();

        return {
            UserStore: UserStore,
            sidebar_show: false
        }
    },
    watch: {
        status (value) {
            this.sidebar_show = value
        }
    },
    computed: {
        name() {
            return this.UserStore.userFname + ' ' + this.UserStore.userLname
        }
    },
    methods: {
        toggle () {
            // return false
            this.sidebar_show = !this.sidebar_show
            this.$emit('changed', this.sidebar_show)
        }
    }
}
</script>

<style scoped>

.sidebar__menu {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;
    background: #4E3391;
    border-radius: 0px 25px 25px 0px;
    width: 334px;
}

.sidebar__menu__header {
    padding: 30px;
}

.sidebar__menu__list {
    padding: 15px 0 15px 20px;
}

.sidebar__logout {
    padding: 40px 0 15px 20px;
}


.sidebar__menu__icon {
    width: 30px;
    padding: 10px 10px 10px 0;
}
.sidebar__menu__avatar {
    width: 75px;
    height: 75px;
}
.sidebar__user-profile__name {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #FFF;
}
.sidebar__user-profile__last-connection {
    font-size: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    opacity: 0.56;
    line-height: 10px;
}
.sidebar__cta {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 800;
}
</style>