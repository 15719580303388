<template>
    <main class="auth__layout">
        <div class="auth__container__main lostpassword__background">
            <div class="auth__container__main__lostpassword__visual">
                <img src="@/assets/visuals/lost-password-visual.png" alt="" v-if="!resetProcessed">
                <img src="@/assets/visuals/lost-password-confirm-visual.png" alt="" v-else>
            </div>
            <div class="auth__container__main__lostpassword__form">
                <AuthLostPasswordForm v-if="!resetProcessed"
                @processed="submit" :resetError="resetError"/>
                <ConfirmContent v-else/>
            </div>
        </div>
    </main>
</template>
<script>
import { useThemeStore } from "@/store/ThemeStore";
import Footer from '@/components/Partials/Footer.vue'
import AuthLostPasswordForm from '@/components/Auth/LostPassword/FormContent.vue'
import ConfirmContent from '@/components/Auth/LostPassword/ConfirmContent.vue'

export default {
    props: {
        resetProcessed: Boolean,
        resetError: Boolean,
        processing: Boolean
    },
    data() {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            LoginTheme: ThemeStore.loginPage,
        }
    },
    components: {
        AuthLostPasswordForm,
        ConfirmContent,
    },
    computed: {
        leftTextColor () {
            return {'color': this.LoginTheme.leftTextColor}
        },
        authLayoutFormBackground() {
            const LoginTheme = this.LoginTheme
            
            return {
                'background-image': 'linear-gradient(to bottom, '
                + LoginTheme.loginColorTop + ', '
                + LoginTheme.loginColorBot + ')'
            }
        }
    },
    methods: {
        submit (res) {
            this.$emit('processed', res)
        },
    }
}
</script>
