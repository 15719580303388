import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueAxios from 'vue-axios'
import AuthMixin from './mixins/authMixin'

import './assets/scss/index.scss'

import App from './App.vue'
import router from './router'
import VueCompositionAPI from '@vue/composition-api'
import './registerServiceWorker'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

require('./vee-validate');

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))
Vue.use(require('vue-cookies'))
Vue.mixin(AuthMixin)

new Vue({
  el: "#app",
  render: h => h(App),
  router,
  pinia
}).$mount('#app')
