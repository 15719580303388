<template>
    <div class="auth__background hidden lg:block">
        <Splide :options="options">
            <SplideSlide v-for="(visual,idx) in ThemeStore.loginPage.visuals" :key="idx">
                <div class="signin__content__splide__item"
                :class="{item1: idx == 0}"
                :style="{
                    'background-image': 'url(\'' + visual.url + '\')'
                }"></div>
            </SplideSlide>
        </Splide>
    </div>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useThemeStore } from "@/store/ThemeStore";
export default {
    data() {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            options: {
                autoplay: true,
                pauseOnHover: false,
                arrows: false,
                keyboard: false,
                pagination: false,
                width: '100%',
                autoHeight: true,
                drag: false,
                type: 'loop',
                perPage: 1,
                perMove: 1,
            }
        }
    },
    components: {
        Splide,
        SplideSlide,
    }
}
</script>
