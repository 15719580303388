
import { useThemeStore } from "@/store/ThemeStore";

export default {
    data() {
        const ThemeStore = useThemeStore();
        return {
            ThemeStore: ThemeStore,
            fetchingUrl: false,
        }
    },
    methods: {
        launchModule(scormCloudId, item) {
            if (this.fetchingUrl || item.locked) {
                return false
            }
            
            const client = this.themeStore.client
            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/' + scormCloudId
            + '/link?client=' + client
            const accessToken = this.$cookies.get('access_token')
            this.fetchingUrl = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            this.$http.get(url, { headers: headers }).then(
                res => {
                    const url = res.data

                    if (this.deviceType == 'desktop') {
                        window.open(url)
                    } else {
                        window.location.href = url
                    }

                    this.fetchingUrl = false
                },
                error => {
                    this.fetchingUrl = false
                    
                    if (parseInt(error.response.status) == 401) {
                        this.refreshToken(() => {
                            this.launcher(scormCloudId)
                        })
                        
                        return
                    }
                }
            )
        }
    }
};