<template>
<div class="home__header__dropdown"
    :class="{opened: dropdownOpened}">
    <div class="home__header__dropdown__menu">
        <button class="home__header__dropdown__cta" @click="toggleDropdown">
            <div class="home__header__dropdown__cta__avatar">
                <span>{{ initials }}</span>
            </div>
            <span>{{ name }}</span>
            <span class="home__header__dropdown__cta__arrow" v-if="dropdownOpened">&#9652</span>
            <span class="home__header__dropdown__cta__arrow" v-else>&#9662</span>
        </button>
        <div class="home__header__dropdown__items">
            <a href="#">Mon profil</a>
            <a href="#" @click="signout">Déconnexion</a>
        </div>
    </div>
</div>
</template>

<script>
import { useUserStore } from "@/store/UserStore";
import AuthMixin from '@/mixins/authMixin'

export default {
  mixins: [AuthMixin],
    data () {
        const UserStore = useUserStore();
        return {
            UserStore: UserStore,
            dropdownOpened: false
        }
    },
    computed: {
        name() {
            return this.UserStore.userFname + ' ' + this.UserStore.userLname
        },
        initials() {
            return this.UserStore.userFname[0] + this.UserStore.userLname[0]
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpened = !this.dropdownOpened
        },
        signout(evt) {
            evt.preventDefault()
            this.logout()
        }
    }
}
</script>