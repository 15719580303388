<template>
<div class="home__parcours__list__filters__container">
    <div class="home__parcours__list__filters items-center justify-center flex">
        <div class="home__parcours__list__filter flex items-center justify-center tooltip rounded-t-2xl" :class="{selected: filter == 'esse'}" @click="switchFilter('esse')">
            <span>Essentiels</span>
        </div>
        <div class="home__parcours__list__filter flex items-center justify-center rounded-t-2xl" :class="{selected: filter == 'tech'}" @click="switchFilter('tech')">
            <span>Techniques</span>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data () {
        return {
            filter: 'esse'
        }
    },
    methods: {
        switchFilter(type) {
            this.filter = type
            this.$emit('type-updated', type)
        }
    }
}
</script>
