<template>
    <header class="flex items-center relative p-4">
        <black-overlay :opened="sidebarOpened" @hide="sidebarOpened = false"/>
        <sidebar-menu class="w-16 md:w-36 text-white text-left" @changed="getSidebarStatus"
        :status="sidebarOpened"/>
        <div class="flex-grow hidden md:block">
            <img :src="ThemeStore.boxedLogo.url" :alt="ThemeStore.boxedLogo.name" class="header__white-logo mx-auto">
        </div>
        <div class="header__menu-label text-white w-16 md:w-36">
            <div class="hidden md:flex items-center justify-end cursor-pointer" @click="logout">
                <img src="@/assets/icons/icon-logout.svg" alt="">
                <span class="ml-2">Déconnexion</span>
            </div>
        </div>
    </header>
</template>

<script>
import BlackOverlay from './BlackOverlay.vue'
import SidebarMenu from './SidebarMenu.vue'
import { useThemeStore } from "@/store/ThemeStore";

export default {
    data () {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            sidebarOpened: false
        }
    },
    components: {
        BlackOverlay,
        SidebarMenu
    },
    methods: {
        getSidebarStatus (status) {
            this.sidebarOpened = status
        }
    }
}
</script>