<template>
    <div class="auth__signin__form">
        <h2>Bienvenue !</h2>
        <h3>Connectez-vous à votre compte</h3>

        <form @submit.prevent="submit">
            <div class="field-holder">
                <input type="text" required v-model="username" id="username"
                :class="{
                    'error': signinError
                }" :disabled="processing"/>
                <label for="username" class="input__label">Votre identifiant</label>
            </div>
            <div class="field-holder">
                <input :type="passwordType" required v-model="password" id="password"
                :class="{
                    'error': signinError
                }" :disabled="processing"/>
                <label for="password" class="input__label">Votre mot de passe</label>
                <TogglePasswordDisplay @togglePassword="togglePassword"/>
            </div>
            <div class="field-holder inline">
                <label class="switch">
                    <input type="checkbox" v-model="remember" value="1">
                    <span class="slider"></span>
                    <span class="label">Se souvenir de moi</span>
                </label>
                <router-link :to="{ name: 'auth.lost-id' }">Mot de passe oublié ?</router-link>
            </div>
            <button type="submit" :disabled="processing"
            class="auth__signin__form__submit"
            :style="{'background-color': ThemeStore.loginPage.buttonColor}">Je me connecte</button>
        </form>
    </div>
</template>
<script>
import { useThemeStore } from "@/store/ThemeStore";
import TogglePasswordDisplay from '@/components/Auth/Signin/TogglePasswordDisplay.vue'
export default {
    props: {
        signinError: Boolean,
        processing: Boolean
    },
    data() {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
            remember: false,
            username: null,
            password: null,
            passwordType: 'password'        }
    },
    components: {
        TogglePasswordDisplay
    },
    methods: {
        submit () {
            this.$emit('processed', {
                username: this.username,
                password: this.password,
                remember: this.remember
            })
        },
        togglePassword () {
            this.passwordType = this.passwordType == 'password' ? 'text' : 'password'
        }
    }
}
</script>
