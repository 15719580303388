<template>
    <footer class="items-center mx-auto footer__logos" v-if="Object.keys(ThemeStore.partners).length > 0">
        <div class="flex items-center justify-between footer__content px-5 rounded-2xl">
            <img :src="partner.url" :alt="partner.name" v-for="(partner,idx) in ThemeStore.partners" :key="idx"/>
        </div>
    </footer>
</template>

<script>
import { useThemeStore } from "@/store/ThemeStore";

export default {
    data () {     
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
        }
    },
}
</script>
