
import { useUserStore } from "@/store/UserStore";

import AuthMixin from '@/mixins/authMixin'
import UIMixin from '@/mixins/uiMixin'

export default {
    mixins: [AuthMixin, UIMixin],
    data () {
        return {
            fetchingUser: true
        }
    },
    methods: {
        fetchUser(callback) {
            const canRedirect = !(this.$route.name == 'auth.signin')
            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/Player'
            const accessToken = this.$cookies.get('access_token')
            
            if (!accessToken && canRedirect) {
                this.$router.push({name: 'auth.signin'})
            }

            this.fetchingUser = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            this.$http.get(url, { headers: headers }).then(
                res => {
                    const UserStore = useUserStore();
                    UserStore.setAvatar(res.data.avatar)
                    UserStore.setFname(res.data.firstName)
                    UserStore.setLname(res.data.lastName)
                    UserStore.setLogin(res.data.login)
                    UserStore.setGroupName(res.data.groupName)
                    UserStore.setGroupCity(res.data.groupCity)
                    UserStore.setDocumentsToDownload(res.data.documentsToDownload)
                    UserStore.setLastConnectionDate(res.data.lastConnectionDate)
                    UserStore.setLastConnectionHour(res.data.lastConnectionHour)
                    UserStore.setPercentDone(res.data.percentDone)
                    UserStore.setStatistics(res.data.statistics)
                    UserStore.setTimeSpent(res.data.timeSpent)
                    UserStore.setLockedTrophiesCount(res.data.lockedTrophiesCount)
                    UserStore.setDocumentsCount(res.data.documentsCount)

                    const callbackType = typeof callback;
                    
                    this.fetchingUser = false

                    if (callbackType == 'function') {
                        callback()
                    }
                },
                error => {
                    this.fetchingUser = false

                    if (error.response.status == 401) {
                        this.refreshToken(() => {
                            this.fetchUser(callback)
                        })
                        
                        return
                    }
                }
            )
        },
    }
};