<template>
    <div class="tips rounded-2xl w-full hidden md:block mb-2 md:mb-0">
        <div class="tips__item text-center px-2 flex items-center">
            <div class="w-full">
                <strong>Astuces</strong>
                
                <Splide :options="options" v-if="items.length > 0" class="mt-5">
                    <SplideSlide v-for="(item,idx) in items" :key="'item-' + idx">
                        <div class="text-center">
                            <img src="@/assets/icons/icon-headset.svg" alt="" class="mx-auto">
                            <p class="mt-2 pb-10">{{ item.description }}</p>
                        </div>
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    </div>
</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';


export default {
    components: {
        Splide,
        SplideSlide,
    },
    data() {
        return {
            items: [
                {
                    description: "Pensez à vous munir d’un casque ou d'écouteurs"
                },
                {
                    description: "Pensez à vous munir d’un casque ou d'écouteurs"
                },
                {
                    description: "Pensez à vous munir d’un casque ou d'écouteurs"
                }
            ],
            fetching: true,
            swiper: null,
            options: {
                autoplay: true,
                pauseOnHover: true,
                arrows: false,
                keyboard: true,
                pagination: true,
                width: '100%',
                autoHeight: true,
                drag: true,
                type: 'loop',
                perPage: 1,
                perMove: 1,
            }
        }
    },
}
</script>
