<template>
  <div class="black-overlay" v-if="opened" @click="hide"></div>
</template>

<script>
export default {
    props: {
        opened: Boolean
    },
    methods: {
        hide () {
            this.$emit('hide')
        }
    }
}
</script>

<style>
.black-overlay {
    width: 100%;
    height: 100%;
    background: rgba(153,143,162,0.5);
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
}
</style>