<template>
    <div class="parcours">
        <div class="md:ml-2 parcours__content rounded-2xl">
            <div class="parcours__header relative px-4 rounded-t-2xl" :style="{'background-image': 'url('+item.background+')'}">
                <div class="parcours__header__content p-4">
                    <p>{{ item.description }}</p>
                </div>
            </div>
            <div class="parcours__progression" :title="item.percentDone + '%'">
                <div class="parcours__progress__bar" :style="{width: item.percentDone + '%'}"></div>
            </div>
            <div class="parcours__footer flex items-center justify-between px-4">
                <span>{{ item.percentDone }}%</span>
                <button @click="launcher(item.scormCloudId)" :disabled="fetchingUrl"
                class="btn btn__cta rounded-full rounded-full py-5 pl-10 pr-5 flex items-center">
                    {{ ctaLabel }}
                    <img src="@/assets/icons/icon-right-arrow.svg" class="ml-5"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import AuthMixin from '@/mixins/authMixin'
import UIMixin from '@/mixins/uiMixin'

export default {
  mixins: [AuthMixin, UIMixin],
    props: {
        item: Object
    },
    data () {
        return {
            fetchingUrl: false,
            current: true,
            options: {
                cutoutPercentage: 85,
                responsive: true, 
                maintainAspectRatio: false, 
                animation: {
                    animateRotate: false
                },
                legend: {
                    display: false
                },
                hover: {mode: null},
                tooltips: {
                    enabled: false
                },
            }
        }
    },
    computed: {
        ctaLabel () {
            let label = '';

            if (this.item.validated || this.item.toredo) {
                label = 'Recommencer';
            } else if (this.item.timeSpent == 0) {
                label = 'Commencer';
            } else {
                label = 'Continuer';
            }

            return label;
        }
    },
    methods: {
        launcher(scormCloudId) {
            if (this.fetchingUrl) {
                return false
            }
            
            const url = process.env.VUE_APP_BASE_URL + '/data/Scorm/' + scormCloudId + '/link'
            const accessToken = this.$cookies.get('access_token')
            this.fetchingUrl = true

            const headers = {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: 'Bearer ' + accessToken
            }

            this.$http.get(url, { headers: headers }).then(
                res => {
                    const url = res.data

                    if (this.deviceType == 'desktop') {
                        window.open(url)
                    } else {
                        window.location.href = url
                    }

                    this.fetchingUrl = false
                },
                error => {
                    this.fetchingUrl = false

                    if (error.response.status == 401) {
                        this.refreshToken(() => {
                            this.launcher(scormCloudId)
                        })
                        
                        return
                    }
                }
            )
        }
    }
}
</script>