<template>
    <div class="layout">
        <slot></slot>
        <div class="w-full bg-white">
            <Footer class="home py-5" v-if="Object.keys(ThemeStore.partners).length > 0"/>
        </div>
    </div>
</template>

<script>
import { useThemeStore } from "@/store/ThemeStore";
import Footer from '@/components/Partials/Footer.vue'

export default {
    data() {
        const ThemeStore = useThemeStore();   
        return {
            ThemeStore: ThemeStore,
        }
    },
    components: {
        Footer        
    }
}
</script>
