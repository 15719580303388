<template>
  <div id="app">
    <router-view v-if="!fetchingTheme && !fetchingUser"/>
  </div>
</template>

<script>
import UIMixin from '@/mixins/uiMixin'
import UserMixin from '@/mixins/userMixin'

export default {
  mixins: [UIMixin, UserMixin],

  mounted () {
    this.fetchUITheme()
    this.fetchUser()
  }
}
</script>
